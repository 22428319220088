<template>
  <Header :isShow="isShow" :homeAct="homeAct" :display="positions"></Header>
  <router-view @getShopCode='getShopCode' @getRoute='getRoute' @showHeader="showHeader" @showFlex="showFlex"></router-view>
  <Footer :isShow="isShow"></Footer>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data(){
      return{
          isShow:1,
          homeAct:'home',
          positions:'relative'
      }
  },
  methods:{
   getShopCode(value){
      this.isShow = value
    },
    getRoute(value){
      
      this.homeAct = value
    },
    showHeader(value){
      this.isShow = value
    },
    showFlex(value){
      this.positions = value
    }
    

  }
}
</script>

<style>
#app {
  font-family: '微软雅黑',"PingFang SC", system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

 
</style>

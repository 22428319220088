<template>
    <div class="header"  ref="headerheight" :style="{height:headerHeight+'px',position:positions}" :class="[showHeader == 0?'hide':'',enClass == 1?'en_class':'']">
        <div class="header_left">
            <div class="logo_div">
                <img src="../assets/logo.png">
            </div>
        </div>
        <div class="header_right">
            <div class="menu_list">
                <ul>
                    <li :class="homeActive=='home'?'active':''"><router-link to="/" @click="getRouter('home')">{{$t('app.home')}}</router-link></li>
                    <li @mouseover="mouseOver" @mouseleave="mouseLeave">
                        <span :class="(homeActive=='boss' || homeActive=='interview' || homeActive=='globalView')?'active':''">{{$t('app.zhoulizl')}}</span>
                        <ul class="second_menu" ref="getheight">
                            <li :class="homeActive=='boss'?'active':''"><router-link to="/boss" @click="getRouter('boss')">{{$t('app.zhouli')}}</router-link></li>
                            <li :class="homeActive=='interview'?'active':''"><router-link to="/interview" @click="getRouter('interview')">{{$t('app.gaoduan')}}</router-link></li>
                            <li :class="homeActive=='globalView'?'active':''"><router-link to="/globalView" @click="getRouter('globalView')">{{$t('app.quanqiu')}}</router-link></li>
                        </ul>
                        <span class="tag fa fa-sort-down"></span>
                    </li>
                    <li @mouseover="cmouseOver" @mouseleave="mouseLeave">
                        <span :class="(homeActive=='companyprofile' || homeActive=='news' || homeActive=='international' || homeActive=='honors')?'active':''">{{$t('app.company')}}</span>
                        <ul class="second_menu" ref="companyheight" @mouseover="cmouseOver" @mouseleave="mouseLeave">
                            <li :class="homeActive=='companyprofile'?'active':''"><router-link to="/companyprofile"  @click="getRouter('companyprofile')">{{$t('app.company_intro')}}</router-link></li>
                            <li :class="homeActive=='news'?'active':''"><router-link to="/news" @click="getRouter('news')">{{$t('app.news')}}</router-link></li>
                            <li :class="homeActive=='international'?'active':''"><router-link to="/international" @click="getRouter('international')">{{$t('app.Resources')}}</router-link></li>
                            <li :class="homeActive=='honors'?'active':''"><router-link to="/honors" @click="getRouter('honors')">{{$t('app.Honors')}}</router-link></li>
                        </ul>
                        <span class="tag fa fa-sort-down"></span>

                    </li>
                    <li @mouseover="imouseOver" @mouseleave="mouseLeave">
                        <span :class="(homeActive=='branddetail' || homeActive=='alliance' || homeActive=='projectdetail' || homeActive=='contact')?'active':''">{{$t('app.chanye')}}</span>
                        <ul class="second_menu three_m" ref="industryheight">
                            <li :class="homeActive=='branddetail'?'active':''"><router-link to="/branddetail" @click="getRouter('branddetail')">{{$t('app.brand_matrix')}}</router-link></li>
                            <li :class="homeActive=='alliance'?'active':''"><router-link to="/alliance" @click="getRouter('alliance')">{{$t('app.Alliance')}}</router-link></li>
                            <li :class="homeActive=='projectdetail'?'active':''"><router-link to="/projectdetail" @click="getRouter('projectdetail')">{{$t('app.Achievements')}}</router-link></li>
                            <li :class="homeActive=='contact'?'active':''"><router-link to="/contact" @click="getRouter('contact')">{{$t('app.Contact')}}</router-link></li>
                        </ul>
                        <span class="tag fa fa-sort-down"></span>

                    </li>
                </ul>
            </div>
            <div class="search">
                <div class="search_input">
                    <input type="text" name="keyword" @keyup.enter.native="searchResult()"/>
                    <span class="fa fa-search"></span>
                </div>
            </div>
            <div class="switch_lang">
                <span @click="changeLanguage('zh')">
                    <!-- <img src="../assets/zh.png" /> -->
                    中
                </span>
                &nbsp;|&nbsp;
                <span @click="changeLanguage('en')">
                    <!-- <img src="../assets/en.png" /> -->
                    EN
                </span>
            </div>
        </div>
    </div>
    <div id="dhbtn" @click="showHead()" v-if="showHeader == 0">
        <!-- <div class="m-sideNav__menuLine"></div>
        <div class="m-sideNav__menuLine"></div>
        <div class="m-sideNav__menuLine"></div> -->
        <img src="../assets/menuline.png"/>
    </div>
</template>
<script>
    let status = 0;
    export default {
    name: 'Header',
    props: {
        isShow:{
            type:Number,
            default:0
        },
        homeAct:{
            type:String,
            default:'home'
        },
        display:{
            type:String,
            default:'relative'
        }
    },
    data(){
        return {
            headerHeight:120,
            homeActive:this.homeAct,
            showHeader:this.isShow,
            enClass:0,
            positions:this.display
        }
    },
    mounted(){
        // window.onload=function(){
        //     console.log(this.$route.name)
        // }
        if (this.$i18n.locale === 'zh') {
                this.enClass = 0
        } else if (this.$i18n.locale === 'en') {
            this.enClass = 1
        }
    },
    watch:{
        homeAct (to, from) {
            this.homeActive = to
            // this.$emit('homeAct', { homeActive: to})
        },
        isShow (to, from) {
            this.showHeader = to
        },
        display (to, from) {
            this.positions = to
        },
        '$i18n.locale'(newValue) {
            if (this.$i18n.locale === 'zh') {
                this.enClass = 0
            } else if (this.$i18n.locale === 'en') {
                this.enClass = 1
            }
        }
    },
    methods:{
        searchResult(){
            let value = event.target.value;
            
            this.$router.push({
                path: 'search',
                query: {kw: value},
            });
        },
        showHead(){
            this.showHeader = 1;
        },
        getRouter(data){
            this.homeActive = data
        },
        mouseOver(){
            if(status == 0){
            let height= this.$refs.getheight.offsetHeight; 
            let header_height = this.$refs.headerheight.offsetHeight;
            
            this.headerHeight = 120 + height;
            status = 1

            }

        },
        mouseLeave(){
            let height= 40; 
            if(status == 1){
            let header_height = this.$refs.headerheight.offsetHeight;
            
            this.headerHeight = header_height - height;
            status = 0
            
            }

        },
        cmouseOver(){
            if(status == 0){
            let height= this.$refs.companyheight.offsetHeight; 
            let header_height = this.$refs.headerheight.offsetHeight;
            
            this.headerHeight = 120 + height;
            status = 1

            }

        },
        imouseOver(){
            if(status == 0){
            let height= this.$refs.industryheight.offsetHeight; 
            let header_height = this.$refs.headerheight.offsetHeight;
            
            this.headerHeight = 120 + height;
            status = 1

            }

        },
       
        changeLanguage(lang){
            this.$i18n.locale = lang   //设置中英文模式
            localStorage.setItem('lang',this.$i18n.locale)   //将用户设置存储到localStorage以便用户下次打开时使用此设置
        },

    }
}
</script>

<style scoped>

    .hide{
        /* display: none; */
        transition:opacity 0s;
        -moz-transition:opacity 0s; /* Firefox 4 */
        -webkit-transition:opacity 0s; /* Safari and Chrome */
        -o-transition:opacity 0s; /* Opera */
        opacity:0;
    }
    .header_left{
        width: 30%;
        padding-left: 8%;
    }
    .en_class .header_left{
        width:10%;
        padding-left:5%;
    }
    .header_right{
        width: 70%;
        display: flex;
    }
    .en_class .header_right{
        width:90%;
    }
    .header_right .menu_list{
        width: 60%;
        text-align:right;
    }
    .en_class .header_right .menu_list{
        width:100%;
    }
    .header_right .search{
        width: 25%;
    }
    .en_class .header_right .search{
        width:20%;
    }
    .header_right .switch_lang{
        width: 10%;
    }
    .header_right ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .second_menu{
        /* height: 40px; */
        position: absolute;
        width: 500px;
        /* left: -203px; */
        /* left:-185px; */
        left: -155%;
        bottom:-30px;
        /* padding-top: 20px!important; */
    }

    .en_class .second_menu{
        left:-85%;
        width:720px;
    }
    .en_class .three_m{
        left: -125%;
    }


    .header_right .menu_list ul li .second_menu{
        display: none;
        line-height: 40px;
        text-align:center;

    }
    .header_right .menu_list ul li .second_menu li{
        padding :0 10px;
        font-size:16px;
    }


    .header_right .menu_list ul li:hover .second_menu{
        display: block;
    }

    .header_right .menu_list ul li:hover> .tag{
        display: block;
    }
    
    .header_right ul li{
        display: inline-block;
        padding: 0 25px;
        position: relative;
        cursor: pointer;
        font-size:18px;
    }

    .header_right .search input{
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border: 2px solid #eee;
        padding-left: 35px;
        width: 65%;
        height: 28px;
    }

    .logo_div{
        text-align: left;
        width: 150px;
        line-height: 120px;
        overflow: hidden;
    }
    .en_class .logo_div{
        width:140px;
    }
    .logo_div img{
        width: 100%;
        vertical-align: middle;
    }
    .tag{
        position: absolute;
        left: 48%;
        top: 70%;
        display: none;

    }
    .en_class .tag{
        left:52%;
    }
    .search_input{
        position: relative;
        text-align: center;
    }
    .search_input .fa{
        position: absolute;
        top: 45%;
        left:15%;
        color: #aaa;

    }

    .header li,.header a{
        color:#7d7d7d;
    }
    .switch_lang img{
        vertical-align: middle;
        cursor:pointer;
    }
    .switch_lang span{
        cursor:pointer;
    }

   
    .header .menu_list ul li.active,.header .menu_list ul li span.active{
        font-weight:bold;
    }

    #dhbtn {
        width: 38px;
        height: 38px;
        position: absolute;
        right: 110px;
        top: 50px;
        z-index: 99;
        cursor: pointer;
    }
    #dhbtn img{
        width: 100%;
    }
    .m-sideNav__menuLine {
        margin-bottom: 4px;
        height: 5px;
        background-color: #ffffff;
        -webkit-transition: -webkit-transform 400ms cubic-bezier(.165, .84, .44, 1) 100ms;
        -moz-transition: -moz-transform 400ms cubic-bezier(.165, .84, .44, 1) 100ms;
        -o-transition: -o-transform 400ms cubic-bezier(.165, .84, .44, 1) 100ms;
        transition: transform 400ms cubic-bezier(.165, .84, .44, 1) 100ms;
    }

</style>

//路由文件
import { createRouter, createWebHashHistory } from 'vue-router'  //将createRouter、createWebHistory引入vue
const routes = [
  {
    path: '/',  
    redirect: '/home', 
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home.vue"),
  },
  {
    path: "/boss",
    name: "boss",
    component: () => import("../views/boss.vue"),
  },
  {
    path: "/interview",
    name: "interview",
    component: () => import("../views/interview.vue"),
  },
  {
    path: "/globalView",
    name: "globalView",
    component: () => import("../views/globalView.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue"),
  },
  {
    path: "/international",
    name: "international",
    component: () => import("../views/international.vue"),
  },
  {
    path: "/honors",
    name: "honors",
    component: () => import("../views/honors.vue"),
  },
  {
    path: "/companyprofile",
    name: "companyprofile",
    component: () => import("../views/companyprofile.vue"),
  },
  {
    path: "/branddetail",
    name: "branddetail",
    component: () => import("../views/branddetail.vue"),
  },
  {
    path: "/projectdetail",
    name: "projectdetail",
    component: () => import("../views/projectdetail.vue"),
  },
  {
    path: "/alliance",
    name: "alliance",
    component: () => import("../views/alliance.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
  },
  {
    path: "/articledetail",
    name: "articledetail",
    component: () => import("../views/articledetail.vue"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/gallery.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search.vue"),
  },


//   {
//     path: '/a',
//     name: 'a',
//     component: () => import('../views/a.vue'),
// 	redirect: '/a/son1',
// 	children:[ //配置子路由
// 		{
// 		path: '/a/son1', //子路由路径前边必须写父路由路径
// 		name: 'ason1',
// 		component: ()=>import("../views/a-son1.vue")	
// 		}
// 	]
//   },
]
const router = createRouter({ //设置为history模式
  history: createWebHashHistory(),
  routes
})
export default router
<template>
    <div class="footer" :class="isShow == 0?'hide':''">
        <div class="footer_div">
            <!-- <img class="wechat_icon" src="../assets/wechat.png"/> -->
            <span style="margin-left:20px;font-weight:bold;">{{$t('app.Business')}}&nbsp;&nbsp;</span>
            <span style="margin-right:20px;font-size:16px;">400-028-2017</span>
            <img class="home_icon" src="../assets/home.png"/>
            <span>&nbsp;&nbsp;<span style="font-weight:bold;">{{$t('app.Headquarters')}}</span>&nbsp;&nbsp;{{$t('app.company_address')}}</span>
        </div>
    </div>
</template>
<script>
 let status = 0;
    export default {
    name: 'Header',
    props: {
        isShow:{
            type:Number,
            default:1
        }
    },
    data(){
        return {
            headerHeight:60
        }
    },
   
    }
</script>
<style scoped>
    .footer{
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #eee;
        color: #7d7d7d;
        font-size: 12px;
    }
    .footer .wechat_icon{
        vertical-align: middle;
        width: 35px;
    }
    .hide{
        display: none;
    }
    .footer .home_icon{
        vertical-align:middle;
    }
</style>

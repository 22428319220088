import { createApp } from 'vue'
import App from './App.vue'
import i18n from './components/language/index';

const app=createApp(App)

import router from "./router/index.js" //引入配置路由文件
import "./assets/css/style.css"
import 'font-awesome/css/font-awesome.css'
app.use(router)//记得在mount之前调用
app.use(i18n)

// app.use(axios)

var browser = {
    versions: function() {
      var u = navigator.userAgent, app = navigator.appVersion;
      return {     //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
      };
    }(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase(),
  };
 
  if (browser.versions.mobile) {//判断是否是移动设备打开。
    //  为true时为移动端跳转到移动端对应域名
    var mobileUrl = 'http://www.happysportschina.com/test/boss.html';
    window.location.href=mobileUrl
 
  }

app.mount('#app')

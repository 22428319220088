
module.exports = {
    language: {
        name: 'English'
    },
    app: {
        home:'首页',
        zhoulizl:'周莉专栏',
        zhouli:'我是周莉',
        gaoduan:'高端访谈',
        quanqiu:'全球视野',
        company:'企业根基',
        company_intro:'企业介绍',
        news:'新闻动态',
        Resources:'国际资源',
        Honors:'全球荣誉',
        chanye:'产业布局',
        brand_matrix:'品牌矩阵',
        Alliance:'产业联盟',
        Achievements:'项目成果',
        Contact: '联系我们',
        Enterprise:'发展历程',
        interview_title:'高端访谈 HIGHLEVEL INTERVIEW',
        international_title:'全球视野 INTERNATIONAL PERSPECTIVE',
        enterprise_title:'企业介绍 ENTERPRISE PROFILE',
        news_title:'新闻动态 NEWS INFORMATION',
        resources_title:'国际资源 INTERNATIONAL RESOURCES',
        honors_title:'全球荣誉 GLOBAL HONORS',
        matrix_title:'品牌矩阵 BRAND MATRIX',
        industrial_title:'产业联盟 INDUSTRIAL ALLIANCE',
        project_title:'项目成果 PROJECT ACHIEVEMENTS',
        contact_title:'联系我们 CONTACT US',
        Domestic:'国内论坛',
        Diplomatic:'外交峰会',
        Business:'商务热线',
        Headquarters:' 成都总部',
        company_address:'中国四川成都市青羊区光华大道601号成都国际非遗博览园数字博览中心二层',
        follow_us:'敬请关注玩湃生活公众号'


    }
}
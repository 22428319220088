module.exports = {
    language: {
        name: '中文'
    },
    app: {
        home:'Home',
        zhoulizl:'Special Column Zhou Li',
        zhouli:'I am Zhou Li',
        gaoduan:'High Level Interview',
        quanqiu:'Global Perspective',
        company:'Enterprise Foundation',
        company_intro:'Enterprise Profile',
        news:'News and Updates',
        Resources:'International Resources',
        Honors:'International Honors',
        chanye:'Industrial Layout',
        brand_matrix:'Brand Matrix',
        Alliance:'Industrial Alliance',
        Achievements:'Project Achievements',
        Contact: 'Contact Us',
        Enterprise:'Enterprise History',
        interview_title:'HIGHLEVEL INTERVIEW',
        international_title:'INTERNATIONAL PERSPECTIVE',
        enterprise_title:'ENTERPRISE PROFILE',
        news_title:'NEWS INFORMATION',
        resources_title:'INTERNATIONAL RESOURCES',
        honors_title:'GLOBAL HONORS',
        matrix_title:'BRAND MATRIX',
        industrial_title:'INDUSTRIAL ALLIANCE',
        project_title:'PROJECT ACHIEVEMENTS',
        contact_title:'CONTACT US',
        Domestic:'Domestic Forum',
        Diplomatic:'Diplomatic Summit',
        Business:'Business Hotline',
        Headquarters:' Chengdu Headquarters',
        company_address:'2F, Digital Expo Center, ICH Park, No. 601, Guanghua Avenue, Qingyang, Chengdu, Sichuan, China',
        follow_us:'Please scan QR code to follow us'
    }
}